<template>

  <div class="shadow rounded-2xl bg-clip-border break-words grid grid-cols-1 w-full font-[HelveticaNeue]">
    
    <div class="font-sans leading-normal text-center text-[12px] py-1 bg-[#1654f4] rounded-t-2xl text-white uppercase">{{data.type}}</div>

    <div class="py-2 px-4 flex flex-row">
      <div class="w-8/12">
        <div class="font-sans leading-normal line-clamp-1 text-[#A098AE]"><span class="font-semibold text-black">#{{data.code}}</span> {{data.eater_name}}</div>
        <div class="text-[#A098AE] font-sm">
          <div class="" v-if="data.type == 'takeaway'">{{ moment(data.created_at).format('HH:mm') }}<span v-if="data.estimated_ready_for_pickup_at"> <Icon.ArrowRight :size="18" class="inline" /> {{ moment(data.estimated_ready_for_pickup_at).format('HH:mm') }}</span></div>
          <div class="" v-else>{{ moment(data.created_at).format('HH:mm') }}<span v-if="data.estimated_delivery_at"> <Icon.ArrowRight :size="18" class="inline" /> {{ moment(data.estimated_delivery_at).format('HH:mm') }}</span></div>
        </div>
      </div>
      
      <div v-if="data.price_with_discount > 0" class="w-4/12">
        <div class="text-right my-auto">{{ toCurrency(data.price_with_discount, '') }} <span class="text-[#F8B604]">€</span></div>
      </div>
    </div>
  </div>


</template>

<script setup>
import { computed } from 'vue';
import * as Icon from "lucide-vue-next";
import moment from 'moment';
</script>

<script>
export default {
  components: {
  },
  props: {
    data: { type: Object },
    color: { type: String, default: 'white' },
    textcolor: { type: String, default: 'black' },
  },
  data() {
    return {
      IconComponent: computed(() => Icon[this.icon]),
    };
  },
  mounted() {
  },
  methods: {

    
    toCurrency(value, currency = "EUR") {
      value = parseFloat(value, 2);
      if (typeof value !== "number") {
        return value;
      }

      var formatter;

      if(currency == ''){
        formatter = new Intl.NumberFormat("pt-PT");
      } else {
        formatter = new Intl.NumberFormat("pt-PT", {
          style: "currency",
          currency: currency,
        });
      }

      return formatter.format(value);
    },


  },
};
</script>
