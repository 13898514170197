 <template>
    <div class="flex items-center justify-between w-full mb-6">
      <h1 class="text-xl font-bold">
          Pedidos
      </h1>
      
      <div class="flex my-auto gap-2">
        <button v-if="store_id != '' && store_id != '*'" @click="createNewOrder(order)" class="px-6 py-3 rounded-xl bg-[#2761F4] text-white">Criar&nbsp;Pedido</button>
        <label class="block text-sm font-bold mt-2">Dia</label>
        <input type="date" v-model="currentDate" @input="updateDate($event.target.value)" class="block w-full bg-transparent text-sm rounded-lg border border-default-200 focus:ring-transparent focus:border-default-200" />
      </div>
    </div>
    <div class="grid grid-col-1 gap-4">

      <Loading :enabled="loading" />


      <div v-if="!loading" class="lg:flex block flex-row gap-3 grow min-h-full" style="min-height: calc(100vh - 170px);">

        <div class="relative mx-auto rounded-xl bg-white lg:w-4/12 w-12/12 p-3 lg:h-full h-auto">

          <div class="flex bg-gray-100 hover:bg-gray-200 rounded-lg transition p-1">
            <nav class="flex gap-x-1" aria-label="Tabs" role="tablist" aria-orientation="horizontal">
              <button type="button" @click="resetCurrentOrder" class="hs-tab-active:bg-white hs-tab-active:text-gray-700 py-3 px-4 inline-flex items-center gap-x-2 bg-transparent text-sm text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 font-sm rounded-lg hover:hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none active" id="segment-item-1" aria-selected="true" data-hs-tab="#segment-1" aria-controls="segment-1" role="tab">
                Novos ({{ orders_new.length }})
              </button>
              <button type="button" @click="resetCurrentOrder" class="hs-tab-active:bg-white hs-tab-active:text-gray-700 py-3 px-4 inline-flex items-center gap-x-2 bg-transparent text-sm text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 font-sm rounded-lg hover:hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none" id="segment-item-2" aria-selected="false" data-hs-tab="#segment-2" aria-controls="segment-2" role="tab">
                Prontos ({{ orders_ready.length }})
              </button>
              <button type="button" @click="resetCurrentOrder" class="hs-tab-active:bg-white hs-tab-active:text-gray-700 py-3 px-4 inline-flex items-center gap-x-2 bg-transparent text-sm text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 font-sm rounded-lg hover:hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none" id="segment-item-3" aria-selected="false" data-hs-tab="#segment-3" aria-controls="segment-3" role="tab">
                Em entrega ({{ orders_delivering.length }})
              </button>
              <button type="button" @click="resetCurrentOrder" class="hs-tab-active:bg-white hs-tab-active:text-gray-700 py-3 px-4 inline-flex items-center gap-x-2 bg-transparent text-sm text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 font-sm rounded-lg hover:hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none" id="segment-item-4" aria-selected="false" data-hs-tab="#segment-4" aria-controls="segment-4" role="tab">
                Finalizados ({{ orders_done.length }})
              </button>
            </nav>
          </div>

          <div class="">
            <div class="p-2" id="segment-1" role="tabpanel" aria-labelledby="segment-item-1">
              <div class="flex flex-wrap gap-4">
                <div v-for="order in orders_new" :key="order.id" class="shadow-soft-xl w-full">
                  <card v-if="order.type == 'delivery'" :data="order" color="#1654f4" textcolor="white" class="cursor-pointer w-full" @click="showOrder(order)" />
                  <card v-else :data="order" color="#7ba0ff" textcolor="white" class="cursor-pointer w-full" @click="showOrder(order)" />
                </div>
              </div>
            </div>




            <div class="p-2 hidden" id="segment-2" role="tabpanel" aria-labelledby="segment-item-2">
              <div class="flex flex-wrap gap-4">
                <div v-for="order in orders_ready" :key="order.id" class="shadow-soft-xl w-full">
                  <card v-if="order.type == 'delivery'" :data="order" color="#466D1D" textcolor="white" class="cursor-pointer w-full" @click="showOrder(order)" />
                  <card v-else :data="order" color="#466D1D" textcolor="white" class="cursor-pointer w-full" @click="showOrder(order)" />
                </div>
              </div>
            </div>


            <div class="p-2 hidden" id="segment-3" role="tabpanel" aria-labelledby="segment-item-3">
              <div class="flex flex-wrap gap-4">    
                <div v-for="order in orders_delivering" :key="order.id" class="shadow-soft-xl w-full">
                  <card :data="order" color="#ffcc00" class="cursor-pointer w-full" @click="showOrder(order)" />
                </div>
              </div>
            </div>



            <div class="p-2 hidden" id="segment-4" role="tabpanel" aria-labelledby="segment-item-4">            
              <div class="flex flex-wrap gap-4">
                <div v-for="order in orders_done" :key="order.id" class="shadow-soft-xl w-full">
                  <card :data="order" color="white" class="cursor-pointer w-full" @click="showOrder(order)" />
                </div>
              </div>
            </div>
          </div>


        </div>
        
        <div class="relative mx-auto rounded-xl bg-white lg:w-8/12 w-12-12 p-6 grow">
          <div v-if="order?.id != null">          
            <div class="w-full bg-red flex flex-row justify-items-stretch">
              <h2 class="text-lg text-black font-black pt-4 w-full">Detalhes do Pedido</h2>
              <div class="text-lg text-black font-black left-0 right-0 text-center absolute">{{ orderStatus(order.status) }}</div>
              <integrator-icon :integrator="order.integrator" class="w-10 my-2 justify-self-end" />
            </div>

            
          <div class="mt-4 overflow-y-auto grow rounded-xl border border-default-400 p-4">
            <div class="flex gap-4">
              <div class="w-6/12">
                <div class="font-bold text-lg text-[#A098AE]"><span class="uppercase text-black">#{{ order.code }}</span> {{ order.eater_name }}</div>
                <div class="text-black"><span class="text-[#A098AE]">Criado:</span> {{ moment(order.created_at).format('HH:mm') }}</div>
              </div>
              <div class="w-3/12">
                <div class="text-black"><span class="text-[#A098AE]">Telemóvel:</span> {{ order.eater_phone }}</div>
                <div class="text-black"><span class="text-[#A098AE]">NIF:</span> {{ order.eater_nif ?? '-' }}</div>
              </div>
              <div class="w-3/12">
                <div v-if="order.driver_name">
                  <div class="text-[#A098AE]">Estafeta</div>
                  <div class="text-black">{{ order.driver_name }}</div>
                  <div class="text-black">{{ order.driver_phone }}</div>
                </div>
              </div>
            </div>
            <hr class="h-px my-8 bg-gray-400 border-0">
            <div class="flex gap-4">
              <div class="w-6/12">
                <div class="text-[#A098AE]">Morada de Entrega</div>
                <div class="text-black flex" v-if="order.address">
                  <div><Icon.MapPin class="text-[#F8B604] w-8"/></div>
                  <div>{{ order.address.address }}, {{ order.address.address_number }} {{ order.address.appartment_number }} - {{ order.address.zipcode }}
                    <br/>{{ order.address.area_level_2 }} - {{ order.address.country }}
                    <br/>{{ order.address.obs }}
                  </div>
                </div>
                <div class="text-black flex" v-else>
                  <div><Icon.MapPin class="text-[#F8B604] w-8"/></div>
                  <div>-</div>
                </div>
              </div>


              <div class="w-3/12">
                <div class="text-[#A098AE]">Tempo Estimado</div>
                <div>{{ order.address?.time ?? '-' }} Min</div>
                
                <div class="text-[#A098AE] mt-2">Distância</div>
                <div>{{ order.address?.distance ?? '-' }} Km</div>
              </div>


              <div class="w-3/12">
                <div class="text-[#A098AE]">Previsão de Entrega</div>
                <div v-if="order.estimated_delivery_at">{{ moment(order.estimated_delivery_at).format('HH:mm') }}</div>
                <div v-else>-</div>
                
                
                <div v-if="order.status == 'DELIVERED'" class="text-[#A098AE] mt-2">Entregue</div>
                <div v-if="order.status == 'DELIVERED'">{{ order.delivered_time ? moment(order.delivered_time).format('HH:mm') : '-'}}</div>
              </div>

            </div>
            <hr v-if="order.items.length > 0" class="h-px my-8 bg-gray-400 border-0">

            <div v-if="order.items.length > 0" class="flex flex-col gap-2">
              <div v-for="item in order.items" :key="item.id" class="flex flex-row">
                <div class="flex-none w-20 hidden">
                  <strong><span>{{ item.quantity }} x </span>{{ item.name }}</strong> ({{ toCurrency(item.price * item.quantity) }})
                </div>
                <div class="grow">
                  <span>{{ item.quantity }} x </span>{{ item.name }} <br/>
                  {{ item.addons }}
                  
                <div v-if="item.obs">
                  <strong>Obs:</strong> {{ item.obs }}
                </div>
                </div>
                <div class="flex-none flex items-center">
                  {{ toCurrency(item.price, '') }} <span class="text-[#F8B604]">€</span>
                </div>
              </div>
            </div>
              
            <hr v-if="order.price_with_discount > 0" class="h-px my-8 bg-gray-400 border-0">
            
            <div v-if="order.price_with_discount > 0" class="flex flex-row ">
              <div class="grow font-bold">Total</div>
              <div class="flex-none">
                {{ toCurrency(order.price_with_discount, '') }} <span class="text-[#F8B604]">€</span>
              </div>
            </div>
          </div>


            
          <div id="receipt_print" class="hidden print:block mb-4">
            <div class="grid grid-cols-1 gap-2">
              <div class="relative">
                <h2 class="flex-auto text-black font-black">Pedido #{{ order.code }}</h2>
                <integrator-icon :integrator="order.integrator" class="w-8 flex-none absolute top-0 right-0" />
              </div>

              <div>
                <div class="capitalize"><strong>Tipo:</strong> {{ order.type }}</div>
                <div><strong>Data:</strong> {{ moment(order.created_at).format('DD/MM/YYYY HH:mm') }}</div>
                <div v-if="order.estimated_delivery_at"><strong>Previsão de entrega:</strong> {{ moment(order.estimated_delivery_at).format('HH:mm') }}</div>
                <div v-if="order.delivered_time"><strong>Entregue:</strong> {{ moment(order.delivered_time).format('HH:mm') }}</div>
                <div v-if="order.price_with_discount"><strong>Valor do Pedido:</strong> {{ toCurrency(order.price_with_discount) }}</div>
              </div>

              <div>
                <div class="bg-slate-200 font-black text-left">Cliente</div>
                <div>
                  <div><strong>Nome:</strong> {{ order.eater_name }}</div>
                  <div><strong>Telemóvel:</strong> {{ order.eater_phone }}</div>
                  <div v-if="order.nif != ''"><strong>NIF:</strong> {{ order.eater_nif }}</div>
                  <div v-if="order.eater_address"><strong>Morada:</strong> {{order.eater_address}}, {{ order.eater_address_number}} {{ order.eater_appartment_number}} - {{ order.eater_zipcode}}</div>
                </div>
              </div>

              <div v-if="order.obs != null && order.obs != ''">
                <div class="bg-slate-200 font-black text-left">Observação</div>
                <div>{{ order.obs }}</div>
              </div>
              
              <div>
                <div class="bg-slate-200 font-black text-left">Itens</div>
                <div class="divide-y divide-dashed divide-black">
                  <div v-for="item in order.items" :key="item.id">
                    <div>
                      <div class="text-left">
                        <strong><span v-if="item.quantity">{{ item.quantity }} x </span>{{ item.name }}</strong> ({{ toCurrency(item.price) }})
                      </div>
                      <div v-if="item.addons" class="">
                        {{ item.addons }}
                      </div>
                      <div v-if="item.obs" class="">
                        <strong>Obs:</strong> {{ item.obs }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

            
          <div class="flex justify-end gap-2 print:hidden p-2">          
              <button v-if="order.integrator != 'manual'" v-print="printOrder" class="px-6 py-3 rounded-xl bg-[#F8B604] text-white capitalize transition-all">
                Imprimir
              </button>
              
              <button v-if="(order.status == 'NEW' || order.status == 'ACCEPTED')" @click="makeReady(order)" class="px-6 py-3 rounded-xl bg-[#2761F4] text-white capitalize transition-all">
                Pronto
              </button>
              
              <button v-if="order.type == 'takeaway' && (order.status == 'NEW' || order.status == 'READY')" @click="makeDelivered(order)" class="px-6 py-3 rounded-xl bg-primary text-white capitalize transition-all hover:bg-primary">
                Entregue
              </button>
            </div>   

          </div>
        </div>
      </div>

      <div v-if="newOrderModalOpened"
      class="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
      id="modal-product-addon"
    >
        <div class="absolute bg-black bg-opacity-80 inset-0 z-0"></div>
        <div class="relative mx-auto my-auto rounded-xl shadow-lg bg-white md:w-9/12 w-11/12 min-h-[300px]">
          <!--content-->
          <div class="w-full h-full relative flex flex-col">
            <div>
            <Icon.X
              @click="closeNewOrder"
              class="cursor-pointer absolute top-1 right-1 z-20"
            />
            <!--body-->
            <div class="absolute z-10 w-full top-0 bg-red">
              <h2 class="text-center text-lg text-black font-black pt-4">Criar novo Pedido</h2>
            </div>

            <div class="mt-12 overflow-y-auto grow">
              <div class="grid grid-cols-1 gap-4">
                <div class="p-6">            
                  <div v-if="!finalConfirm" class="relative w-full gap-4 flex flex-col">

                    <div class="w-full gap-4 flex flex-col md:flex-row">
                      <div class="flex-auto">
                        <label
                          class="block text-sm font-medium text-default-900 mb-2"
                          >Nome Cliente</label>
                        <input
                          rows="3" maxlength="25"
                          class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200"
                          type="text"
                          v-model="eater_name"
                        />
                      </div>

                      <div class="flex-auto">
                        <label
                          class="block text-sm font-medium text-default-900 mb-2"
                          >Telemóvel</label>
                        <input
                          rows="3" maxlength="13"
                          class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200"
                          type="text"
                          v-model="eater_phone"
                        />
                      </div>

                      <div class="flex-auto">
                        <label
                          class="block text-sm font-medium text-default-900 mb-2"
                          >NIF</label>
                        <input
                          rows="3" maxlength="9"
                          class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200"
                          type="text"
                          v-model="eater_nif"
                        />
                      </div>
                    </div>
                    <div>
                      <label v-if="eater_name.length > 4" class="block text-sm font-medium text-default-900 mb-2">Morada</label>
                      <div v-if="eater_name.length > 4" class="relative w-full">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                          <Icon.MapPin :size="15" class="w-4 h-4 text-gray-500" />
                        </div>

                        <input type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                        placeholder="Pesquisar" 
                        ref="streetRef"
                        role="presentation"
                        autocomplete="off"
                        @input="updateInternalValue"
                        v-model="searchText" />
                      </div>
                    </div>
                  </div>
            
                  <Loading :enabled="newOrderLoading" />

                  <div v-if="!newOrderLoading">

                    <div v-if="!confirmMode && sugestionsList.length > 0 && searchText != '' && !finalConfirm">

                      <div
                        v-for="sugestion in sugestionsList"
                        :key="sugestion.place_id"
                        class="flex gap-2 hover:bg-gray-200 rounded-md shadow my-2 cursor-pointer p-2"
                        @click="onAddressSelected(sugestion)"
                      >
                        <div class="w-10 justify-center items-center my-auto">
                          <Icon.MapPin class="m-auto" />
                        </div>

                        <div class="text-start">
                          <div>{{ sugestion.structured_formatting.main_text }}</div>
                          <div class="text-xs">
                            {{ sugestion.structured_formatting.secondary_text }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div v-if="sugestionsList.length == 0 && searchText != undefined && searchText != '' && searchText.length >= 5 && !confirmMode && !finalConfirm">
                      <div class="mt-6">
                        Não encontramos nenhuma morada, tente novamente.
                      </div>
                    </div>

                    
                    <div v-if="confirmMode" class="grid grid-cols-1 gap-2">
                      <div class="flex gap-2 hover:bg-gray-200 rounded-md shadow my-2 cursor-pointer p-2 bg-gray-300">
                        <div class="w-10 justify-center items-center my-auto">
                          <Icon.MapPin class="m-auto" />
                        </div>

                        <div class="text-start">
                          <div>{{ confirmPlace }}</div>
                          <div class="text-xs">
                            {{ confirmPlace2 }}
                          </div>
                        </div>
                      </div>

                      <div class="">
                        <label
                          class="block text-sm font-medium text-default-900 mb-2"
                          >Número*</label>
                        <input
                          rows="3"
                          class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200"
                          type="text"
                          v-model="address_number"
                        />
                      </div>

                      <div class="">
                        <label
                          class="block text-sm font-medium text-default-900 mb-2"
                          >Número Andar/apartamento</label
                        >
                        <input
                          class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200"
                          type="text"
                          v-model="appartment_number"
                        />
                      </div>

                      <div class="">
                        <label
                          class="block text-sm font-medium text-default-900 mb-2"
                          >Observações para entrega</label
                        >
                        <textarea
                          rows="3"
                          class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200"
                          type="text"
                          v-model="confirmObs"
                        />
                      </div>

                      <div class="">
                        <label class="block text-sm font-medium text-default-900 mb-2">Tempo de preparo (em minutos)</label>
                        <input maxlength="2"
                          class="block w-full bg-transparent rounded-lg py-2.5 px-4 border border-default-200 focus:ring-transparent focus:border-default-200"
                          type="number"
                          v-model="preparation_time"
                        />
                      </div>
                      
                      <div v-if="address_number_error != ''" class="my-2 text-red-700">{{ address_number_error }}</div>
                      <div class="flex justify-end mt-2">
                        <button
                        @click="onAddressSelected2()"
                          class="px-6 py-3 rounded-lg bg-primary text-white capitalize transition-all hover:bg-primary"
                        >
                          Confirmar
                        </button>
                      </div>
                    </div>

                    
                    <div v-if="finalConfirm" class="grid grid-cols-1 gap-2">
                      <div class="">
                        <b>Cliente:</b> {{ eater_name }}
                        <br/>
                        <b>Telemóvel:</b> {{ eater_phone }}
                        <br/>
                        <b>NIF:</b> {{ eater_nif }}

                        <hr class="h-px my-4 bg-gray-400 border-0">

                        <b>Morada:</b> {{ confirmPlace }} - {{ confirmPlace2 }}
                        <br/>
                        <b>Número:</b> {{ address_number }}
                        <br/>
                        <b>Número Andar/apartamento:</b> {{ appartment_number }}
                        <br/>
                        <b>Código Postal:</b> {{ address_zipcode }}
                        <br/>
                        <b>Observações para entrega:</b> {{ confirmObs }}
                        <br/>
                        <b>Tempo de preparo:</b> {{ preparation_time }} minuto{{ preparation_time > 1 ? 's' : '' }}

                        <hr class="h-px my-4 bg-gray-400 border-0">

                        <b>Distância:</b> {{ quoteDistance }} Km
                        <br/>
                        <b>Valor:</b> {{ toCurrency(quotePrice) }}
                      </div>
                      
                      <div v-if="address_number_error != ''" class="my-2 text-red-700">{{ address_number_error }}</div>
                      <div class="flex justify-end mt-2">
                        <button
                        @click="createNewOrderConfirmed()"
                          class="px-6 py-3 rounded-lg bg-[#2761F4] text-white"
                        >
                          Finalizar Pedido
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>       
      </div>
    </div>
  </div>

</template>

<script setup>
import * as Icon from "lucide-vue-next";
import _debounce from "lodash/debounce";
import moment from 'moment';
import OrderCard from "./OrderCard.vue";
import Loading from "../Loading.vue";
</script>

<script>
export default {
  components: {
    'card': OrderCard,
  },
  props: {
    store_id: { type: String },
  },
  data() {
    return {
      loading: true,
      newOrderLoading: false,
      currentDate: moment().format('YYYY-MM-DD'),
      orders: [],
      orders_new: [],
      orders_ready: [],
      orders_accepted: [],
      orders_delivering: [],
      orders_done: [],
      order: {},
      quoteId: '',
      quotePrice: 0,
      quoteDistance: 0,
      quoteExpiration: null,
      address_zipcode: '',
      address_lat: '',
      address_lng: '',
      address_area_level_1: '',
      address_area_level_2: '',
      address_area_level_3: '',
      address_country: '',
      preparation_time: 20,
      newOrder: {},
      newOrderTemp: {},
      sugestionsList: [],
      confirmMode: false,
      finalConfirm: false,
      confirmPlace: null,
      confirmPlace2: null,
      confirmPlaceId: 0,
      confirmObs: "",
      confirmObsDefault: "Encontro na porta",
      eater_name: "",
      eater_phone: "",
      eater_nif: "",
      address_number: "",
      appartment_number: "",
      address_number_error: "",
      newOrderModalOpened: false,
      printOrder: {
              id: "receipt_print",
              popTitle: 'Pedido #',
             beforeOpenCallback (vue) {
                //vue.printLoading = true
                //console.log('打开之前')
              },
              openCallback (vue) {
                //vue.printLoading = false
                //console.log('执行了打印')
              },
              closeCallback (vue) {
                //console.log('关闭了打印工具')
              }
            }
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const self = this;
      this.loading = true;

      this.order = {};

      axios
        .post("orders/by_date", {'store': this.store_id, 'date': this.currentDate})
        .then((response) => {
          self.orders = response.data.orders;

          self.orders_new = self.orders.filter((order) => order.status == 'NEW');
          self.orders_ready = self.orders.filter((order) => order.status == 'READY');
          self.orders_accepted = self.orders.filter((order) => order.status == 'ACCEPTED');
          self.orders_delivering = self.orders.filter((order) => order.status == 'ON_DELIVERY');
          self.orders_done = self.orders.filter((order) => order.status == 'DELIVERED');
        })
        .catch((error) => {
          self.orders = [];
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    checkIsValidNumber(r) {
      if (Number.isNaN(Number.parseInt(r))) {
        return '';
      }
      return parseFloat(r);
    },

     resetCurrentOrder(event){
      this.order = {};
     },

    makeReady(order){
      const self = this;
      this.loading = true;

      axios
        .post("orders/set_ready", {'orderid': order.id})
        .then((response) => {
        })
        .catch((error) => {
          self.orders = [];
          console.log(error);
        })
        .finally(() => {
          self.loading = false;
          self.newOrderModalOpened = false;
          self.loadData();
        });
    },

    makeDelivered(order){
      const self = this;
      this.loading = true;

      axios
        .post("orders/set_delivered", {'orderid': order.id})
        .then((response) => {
        })
        .catch((error) => {
          self.orders = [];
          console.log(error);
        })
        .finally(() => {
          self.loading = false;
          self.newOrderModalOpened = false;
          self.loadData();
        });
    },

    sendToZonesoft(order){
      const self = this;
      this.loading = true;

      axios
        .post("/admin/orders/send_to_zonesoft", {'orderid': order.id})
        .then((response) => {
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          self.loading = false;
          alert('send ok');
        });
    },

    createNewOrder(){
      this.newOrder = {};
      this.newOrderTemp = {};
      this.sugestionsList = [];
      this.confirmMode = false;
      this.finalConfirm = false;
      this.eater_name = '';
      this.eater_phone = '';
      this.eater_nif = '';
      this.newOrderModalOpened = true;
      this.quoteDistance = 0;
      this.quotePrice = 0;
      this.preparation_time = 20;
    },

    showOrder(order){
      console.log('order', order);
      this.order = order;
    },

    closeNewOrder(){
      this.confirmMode = false;
      this.finalConfirm = false;
      this.newOrderModalOpened = false;
      this.eater_name = '';
      this.eater_phone = '';
      this.eater_nif = '';
      this.newOrder = {};
      this.searchText = '';
      this.sugestionsList = [];
      this.quoteId = '';
      this.quoteDistance = 0;
      this.quotePrice = 0;
      this.preparation_time = 20;
    },

    updateDate(date){
      this.order = {};
      this.currentDate = moment(date).format('YYYY-MM-DD');
      this.loadData();
    },

    onAddressInput(search) {
      const self = this;
      if(search.length <= 5) return;
      this.newOrderLoading = true;
      this.historyMode = false;
      this.confirmMode = false;
      this.confirmObs = this.confirmObsDefault;
      this.address_number = "";
      this.appartment_number = "";
      this.address_number_error = '';

      axios
        .post("/addressSearch", { search: search })
        .then((response) => {
          this.sugestionsList = response.data.predictions ?? [];
        })
        .catch((error) => {
          this.sugestionsList = [];
          console.log(error);
        })
        .finally(() => {
          self.newOrderLoading = false;
        });
    },

    onAddressSelected(suggestion) {
      console.log('onAddressSelected', suggestion)
      this.confirmMode = true;

      this.confirmPlace = suggestion.terms[0]['value'];
      this.address_number = this.checkIsValidNumber(suggestion.terms[1]['value']);
      this.confirmPlace2 = suggestion.structured_formatting.secondary_text;
      this.confirmPlaceId = suggestion.place_id;
    },

    onAddressSelected2() {
      const self = this;
      this.address_number_error = "";

      if(this.address_number == ""){
        this.address_number_error = "Digite o número da morada";
        return;
      }

      this.newOrderLoading = true;      
      this.confirmMode = false;

      axios.post("/merchant/check_price_glovo",
        {
          store_id: this.store_id,
          placeId: this.confirmPlaceId,
          address: this.confirmPlace,
          address_number: this.address_number,
          appartment_number: this.appartment_number,
          obs: this.confirmObs,
          delay: this.preparation_time,
        })
        .then((response) => {
          console.log(response);
          self.quoteId = response.data.quote.quoteId;
          self.quotePrice = parseFloat(response.data.quote.quotePrice * 1.23 + 1.2, 2).toFixed(2);
          self.quoteDistance = parseFloat(response.data.quote.distanceInMeters / 1000).toFixed(1);
          self.quoteExpiration = response.data.quote.expiresAt;

          self.address_zipcode = response.data.address.zipcode ?? '';
          self.address_lat = response.data.address.lat ?? null;
          self.address_lng = response.data.address.lng ?? null;
          self.address_area_level_1 = response.data.address.area_level_1 ?? null;
          self.address_area_level_2 = response.data.address.area_level_2 ?? null;
          self.address_area_level_3 = response.data.address.area_level_3 ?? null;
          self.address_country = response.data.address.country ?? null;

          self.finalConfirm = true;          
          //location.reload();
        })
        .catch((error) => {
          self.error = error.response?.data?.error ?? "Ocorreu um erro, tente novamente por favor!";
          alert(error);
        })
        .finally(() => {
          self.newOrderLoading = false;
        });
    },

    updateInternalValue(event) {
      this.touched = true;
      this.updateValue(event.target.value);
    },
    updateValue: _debounce(function (value) {
      this.touched = false;
      this.onAddressInput(value);
    }, 600),

    createNewOrderConfirmed(){
      if(confirm('Tem certeza que deseja criar o pedido?\n\nSerá possível cancelar, mas poderá existir custos')){
        
        const self = this;
        self.newOrderLoading = true;
        this.address_number_error = "";

        if(this.address_number == ""){
          this.address_number_error = "Digite o número da morada";
          return;
        }
        
        this.confirmMode = false;
       

        axios.post("/merchant/request_glovo",
          {
            quoteId: this.quoteId,
            quotePrice: this.quotePrice,
            quoteDistance: this.quoteDistance,
            quoteExpiration: this.quoteExpiration,
            store_id: this.store_id,
            placeId: this.confirmPlaceId,
            address: this.confirmPlace,
            address_number: this.address_number,
            appartment_number: this.appartment_number,
            obs: this.confirmObs,
            delay: this.preparation_time,

            eater_name: this.eater_name,
            eater_phone: this.eater_phone,
            eater_nif: this.eater_nif,

            zipcode: this.address_zipcode,
            lat: this.address_lat,
            lng: this.address_lng,
            area_level_1: this.address_area_level_1,
            area_level_2: this.address_area_level_2,
            area_level_3: this.address_area_level_3,
            country: this.address_country
          })
          .then((response) => {
            console.log(response);
            
            self.loadData();
          })
          .catch((error) => {
            self.error = error.response?.data?.error ?? "Ocorreu um erro, tente novamente por favor!";
            alert(error);
          })
          .finally(() => {
            self.newOrderLoading = false;
          });











      }else{
        alert('NÃO cria!');
      }
    },

    orderStatus(value){
      switch (value) {
        case "NEW":
          return "Novo";
        case "ACCEPTED":
          return "Aceito";
        case "READY":
          return "Pronto";
        case "ON_DELIVERY":
          return "Em Entrega";
        case "FINISHED":
        case "DELIVERED":
          return "Finalizado";
        case "CANCELED":
          return "Cancelado";
        case "REJECTED":
          return "Rejeitado";
        case "BACK_TO_NEW":
          return "Devolvido";
        case "BACK_TO_ACCEPT":
          return "Devolvido";
        default:
          return value;
      }
    },

    toCurrency(value, currency = "EUR") {
      value = parseFloat(value, 2);
      if (typeof value !== "number") {
        return value;
      }

      var formatter;

      if(currency == ''){
        formatter = new Intl.NumberFormat("pt-PT", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        formatter = new Intl.NumberFormat("pt-PT", {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }

      return formatter.format(value);
    },
  },
};
</script>
